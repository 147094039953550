
import Button from "@/components/Button.vue";
import Toast from "@/components/Toast.vue";
import { defineComponent } from "vue";
import '@fortawesome/fontawesome-free/css/all.css';

// Utils
import utilities from "../utilities";

export default defineComponent({
  components: {
    Button,
    Toast,
  },
  props: {
    product: {
      type: Object,
      required: true,

    },
    odd: Boolean
  },
  data() {
    return {
      hover: false,
      validated: false,
    }
  },
  async mounted() {    //validación credenciales ok
    let valid = await (await utilities.isValidated()).isValid;
    this.validated = valid;
  },
  methods: {
    async addProduct2(product: any) {
      //let valid = await (await utilities.isValidated()).isValid;
      const toast = this.$refs.toast as any;
      if (product.no_stock != null && product.no_stock === 1) {
        toast.show("Lo sentimos, este producto no está disponible");
        return;
      }

      this.$store.commit("addProduct2", product);
      toast.show("Agregaste un producto al carrito");

    },
  }
});
