
import { defineComponent } from 'vue'
import ProductItem from '@/components/ProductItem.vue';
import utilities from "../utilities";

export default defineComponent({
  components: {
    ProductItem
  },
  data() {
    return {
      category: 'todos',
      products: [],
      seeds: []
    }
  },
  async mounted() {
    this.products = await utilities.getProducts('productos') as any;
    this.seeds = await utilities.getProducts('semillas') as any;
  },
  computed: {
    productsFiltered() {
      if (this.category === 'semillas') {
        return this.seeds;
      } else if (this.category === 'productos') {
        return this.products;
      } else {
        return [...this.products, ...this.seeds];
      }
    }
  },
  methods: {
    seleccionarCategoria(categoria: string) {
      this.category = categoria;
    }
  }
});
